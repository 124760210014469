<!-- 字典数据编辑弹窗 -->
<template>
  <el-dialog
      center
    :title="isUpdate?'修改':'添加'"
    :visible="visible"
    width="440px"
    :destroy-on-close="true"
    :lock-scroll="false"
    @update:visible="updateVisible">
    <el-form
      :model="form"
      ref="form"
      :rules="rules"
      label-width="100px">
      <el-form-item
          label="字典类型:">
        <el-input
            :disabled="true"
            v-model="this.code"
            placeholder="请输入字典类型"
            clearable/>
      </el-form-item>
      <el-form-item
        label="字典标签:"
        prop="label">
        <el-input
          v-model="form.label"
          placeholder="请输入字典标签"
          clearable/>
      </el-form-item>
      <el-form-item
        label="字典键值:"
        prop="value">
        <el-input
          v-model="form.value"
          placeholder="请输入字典键值"
          clearable/>
      </el-form-item>
      <el-form-item
        label="排序号:"
        prop="sort">
        <el-input-number
          v-model="form.sort"
          controls-position="right"
          :min="0"
          :max="9999"
          placeholder="请输入排序号"
          class="ele-fluid ele-text-left"/>
      </el-form-item>
      <el-form-item
          label="状态:"
          prop="status">
        <el-radio-group v-model="form.status">
          <el-radio :label="0">正常</el-radio>
          <el-radio :label="1">停用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注:">
        <el-input
          v-model="form.note"
          placeholder="请输入备注"
          :rows="3"
          :maxlength="200"
          type="textarea"/>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button
        @click="updateVisible(false)">取消
      </el-button>
      <el-button
        type="primary"
        @click="save"
        :loading="loading">保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import {AddDictdata,EditDictdata} from "@/api/system";

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,

    id: Number,

    code:String
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 表单验证规则
      rules: {
        label: [
          {required: true, message: '请输入字典标签', trigger: 'blur'}
        ],
        value: [
          {required: true, message: '请输入字典键值', trigger: 'blur'}
        ],
        sort: [
          {required: true, message: '请输入排序号', trigger: 'blur'}
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false
    };
  },
  watch: {
    data() {
      if (this.data) {
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          //判断是新增还是修改
          if(this.isUpdate === false){
            this.form.dict_id = this.id
            AddDictdata(this.form).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }else {
            let data = this.form;
            EditDictdata(data.id,data).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    }
  }
}
</script>

<style scoped>
</style>
