<template>
  <!--字典数据-->
  <div>
    <div>
      <div style="display: flex;align-items: center;justify-content: space-between;">
        <div>
          <span style="font-size: 24px;font-weight: 500;color: #707070;">字典数据</span>
        </div>
        <div>
          <el-button style="background: #E4E5E9!important;border-color: #E4E5E9!important;color: #3F4157!important;" @click="returnPage()">返回</el-button>
        </div>
      </div>


      <el-row :gutter="15" style="margin-top: 20px;">
        <el-col :span="24">
          <!--数据表格-->
          <el-card shadow="never">
            <div class="card">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    数据列表
                  </div>
                  <div class="Datalist-botton" style="margin-left: 20px">
                    <el-button class="custom-button" @click="openEdit(null)"  v-auths="[`${$config.uniquePrefix}system:dict:save`]">
                      <i class="el-icon-tianjia" />
                      <span style="vertical-align: middle">新增</span>
                    </el-button>
                  </div>
                </div>
                <div>
                  <!--<el-button>导出数据</el-button>-->
                  <!--<el-button @click="ListSetup()">列表设置</el-button>-->
                </div>
              </div>

              <div class="card_b">
                <!-- 数据表格 -->
                <my-table
                    ref="myTable"
                    :columns="columns"
                    :tableData="List"
                    :headerCellStyle="headerCellStyle"
                    :rowClassName="rowClassName"
                    :total="total"
                    :customsFromWhere="where"
                    :loading="loading"
                    @select="select"
                    @ChangeSize="ChangeSize"
                    @currentChange="currentChange">

                  <template v-slot:status= "{ scope }">
                    <span v-if="scope.row.status === 0">正常</span>
                    <span v-else>停用</span>
                  </template>

                  <!-- 不使用数据可不接收scope值 -->
                  <template v-slot:operationSlot= "{ scope }">
                    <el-link :underline="false" type="warning"  v-auths="[`${$config.uniquePrefix}system:dict:put`]" @click="openEdit(scope.row)" style="margin-right: 10px;">修改</el-link>
                    <el-popconfirm title="确定删除吗？" @confirm=" del(scope.row)">
                      <el-link :underline="false"  v-auths="[`${$config.uniquePrefix}system:dict:delete`]" slot="reference">删除</el-link>
                    </el-popconfirm>
                  </template>
                </my-table>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <!-- 编辑弹窗 -->
    <dict-edit :visible.sync="showEdit" :code="code" :id="pid" :data="current" @done="reload"/>

  </div>
</template>

<script>
// 权限
import {ShowTable} from '@/utils'
// 引入的接口
import {
  getDictdeta,
  DelDictdata,
} from '@/api/system'

import DictEdit from './dict-edit';

export default {
  // 组件生效
  components: {
    DictEdit
  },
  props: {
    // 修改回显的数据
    data: Object
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "字典标签",
          prop: "label",
          isShow: true
        },
        {
          label: "字典键值",
          prop: "value",
          isShow: true
        },
        {
          label: "字典排序",
          prop: "sort",
          isShow: true
        },
        {
          label: "状态",
          prop: "status",
          slot:'status',
          isShow: true
        },
        // {
        //   label: "描述",
        //   prop: "annotation",
        //   isShow: true
        // },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true
        },
      ],

      // 当前编辑数据
      current: null,
      // 是否显示编辑弹窗
      showEdit: false,

      pid:0,
      code:'',

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // 所属字典
    console.log(this.data);
    this.pid = this.data.id;
    this.code = this.data.code;

    this.getList();
  },

  methods:{
    // 刷新接口数据
    reload(){
      this.getList();
    },

    //获取字典数据
    getList(){
      getDictdeta(this.data.id).then(res => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch(err => {
        this.$message.error(err.msg);
        this.loading = false;
      })
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },

    // 删除
    del(row){
      // console.log(row);
      DelDictdata(row.id).then(res => {
        if (res.code === 200) {
          this.$message.success(res.msg);
          this.getList();
        }else{
          this.$message.error(res.msg);
        }
      }).catch(e => {
        console.log(e)
      })
    },


    // 打开编辑
    openEdit(row){
      this.current = row;
      this.showEdit = true;
    },

    //返回按钮
    returnPage(){
      this.$emit('showDetails',false)
    },

  }

}
</script>

<style lang="scss" scoped>

</style>
