<template>
  <!--字典管理-->
  <div style="padding: 20px">
    <div v-if="showDetails === false">
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">筛选条件</div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="90px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent
            >
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="字典名称:">
                    <el-input
                      clearable
                      v-model="where.facilitator_name"
                      placeholder="请输入字典名称"
                    />
                  </el-form-item>
                </el-col>
                <!-- Dictionary name -->
                <el-col :lg="6" :md="12">
                  <el-form-item label="字典编码:">
                    <el-input
                      clearable
                      v-model="where.facilitator_name"
                      placeholder="字典编码"
                    />
                  </el-form-item>
                </el-col>
                <el-col :lg="4" :md="12">
                  <el-form-item label=" ">
                    <div class="ele-form-actions">
                      <el-button
                        type="primary"
                        class="ele-btn-icon"
                        @click="QueryList"
                        >查询
                      </el-button>
                      <el-button @click="reset">清空</el-button>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <el-row :gutter="15" style="margin-top: 20px">
        <el-col :span="6">
          <el-card shadow="never">
            <div class="card">
              <div>
                <el-button
                  @click="openEdit(null)"
                  type="primary"
                  icon="el-icon-plus"
                  class="ele-btn-icon"
                  size="small"
                  v-auths="[`${$config.uniquePrefix}system:dict:save`]"
                  >添加
                </el-button>
                <el-button
                  @click="openEdit(current)"
                  type="warning"
                  icon="el-icon-edit"
                  class="ele-btn-icon"
                  :disabled="!current"
                  size="small"
                  v-auths="[`${$config.uniquePrefix}system:dict:put`]"
                  >修改
                </el-button>
                <el-popconfirm title="确定删除吗？" @confirm="remove(current)">
                  <el-button
                    style="margin-left: 10px"
                    slot="reference"
                    type="danger"
                    icon="el-icon-delete"
                    class="ele-btn-icon"
                    :disabled="!current"
                    size="small"
                     v-auths="[`${$config.uniquePrefix}system:dict:delete`]"
                    >删除
                  </el-button>
                </el-popconfirm>
              </div>
              <div class="card_b no-multiple">
                <!-- 数据表格 -->
                <!--<my-table-->
                <!--    height="calc(100vh - 261px)"-->
                <!--    ref="Table"-->
                <!--    :columns="Dictcolumns"-->
                <!--    :tableData="DictList"-->
                <!--    :headerCellStyle="headerCellStyle"-->
                <!--    :rowClassName="rowClassName"-->
                <!--    :loading="Dictloading"-->
                <!--    row-key="id"-->
                <!--    @selection-change="handleSelectionChange"-->
                <!--    @select="selectDict">-->
                <!--</my-table>-->
                <!-- 这是渲染一级字典表格的 -->
                <el-table
                  align="center"
                  border
                  height="calc(100vh - 261px)"
                  ref="Table"
                  :data="DictList"
                  tooltip-effect="dark"
                  style="width: 100%"
                  row-key="id"
                  :headerCellStyle="headerCellStyle"
                  @select="selectDict"
                  @selection-change="handleSelectionChange"
                >
                  <el-table-column type="selection" width="55">
                  </el-table-column>
                  <el-table-column label="编号" prop="id"> </el-table-column>
                  <el-table-column label="字典名称" prop="name">
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </el-card>
        </el-col>

        <el-col :span="18">
          <!--数据表格-->
          <el-card shadow="never">
            <div class="card">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">数据列表</div>
                  <div class="Datalist-botton" style="margin-left: 20px">
                    <el-button
                      class="custom-button"
                      @click="openCategory(null)"
                        
                    >
                      <i class="el-icon-tianjia" />
                      <span style="vertical-align: middle">新建字典</span>
                    </el-button>
                  </div>
                </div>
                <div>
                  <!--<el-button>导出数据</el-button>-->
                  <!--<el-button @click="ListSetup()">列表设置</el-button>-->
                </div>
              </div>

              <div class="card_b">
                <!-- 二级字典表格 -->
                <!-- 数据表格 -->
                <my-table
                  height="calc(100vh - 315px)"
                  ref="myTable"
                  :columns="columns"
                  :tableData="List"
                  :headerCellStyle="headerCellStyle"
                  :rowClassName="rowClassName"
                  :total="total"
                  :customsFromWhere="where"
                  :loading="loading"
                  @select="select"
                  @ChangeSize="ChangeSize"
                  @currentChange="currentChange"
                >
                  <template v-slot:code="{ scope }">
                    <span
                      style="color: #20a0ff; cursor: pointer"
                      @click="openDetails(scope.row)"
                         v-auths="[`${$config.uniquePrefix}system:dict:info`]"
                      >{{ scope.row.code }}</span
                    >
                  </template>

                  <!-- 不使用数据可不接收scope值 -->
                  <template v-slot:operationSlot="{ scope }">
                    <el-link
                      :underline="false"
                      type="warning"
                      @click="openCategory(scope.row)"
                      style="margin-right: 10px"
                        v-auths="[`${$config.uniquePrefix}system:dict:put`]"
                      >修改</el-link
                    >
                    <el-popconfirm
                      title="确定删除吗？"
                      @confirm="del(scope.row)"
                    >
                      <el-link :underline="false" slot="reference"
                       v-auths="[`${$config.uniquePrefix}system:dict:delete`]"
                        >删除</el-link
                      >
                    </el-popconfirm>
                  </template>
                </my-table>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>

      <!-- 编辑弹窗 -->
      <dict-edit :visible.sync="showEdit" :data="editData" @done="reload" />

      <!--字典类目-->
      <category-edit
        :visible.sync="showcategoryEdit"
        :id="pid"
        :data="category"
        @done="reload"
      />
    </div>

    <!--字典数据模块-->
    <Details
      v-if="showDetails === true"
      :data="dictionaryData"
      @showDetails="showdetalis"
    />
  </div>
</template>

<script>
// 权限
import { ShowTable } from "@/utils";
// 引入的接口
import { getDictionary, getDictcategory, DelDictcategory } from "@/api/system";

import DictEdit from "./dict-edit";
import CategoryEdit from "./category-edit.vue";

// 引入字典数据模块
import Details from "./details/details.vue";

export default {
  // 组件生效
  components: {
    DictEdit,
    CategoryEdit,
    Details,
  },
  data() {
    return {
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading: true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true,
        },
        {
          label: "字典编码",
          // type: "index",
          // width: "70"
          prop: "code",
          slot: "code",
          isShow: true,
        },
        {
          label: "字典名称",
          prop: "name",
          isShow: true,
        },
        {
          label: "排序",
          prop: "sort",
          isShow: true,
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true,
        },
      ],
      //copy的第二份数据
      DictList_copy: [],
      // 表格数据
      DictList: [],
      // 总条目数
      Dicttotal: 0,
      //加载动画
      Dictloading: true,
      // 表头数据
      Dictcolumns: [
        {
          type: "selection",
          isShow: true,
        },
        {
          label: "编号",
          // type: "index",
          // width: "70"
          prop: "level",
          isShow: true,
        },
        {
          label: "字典名称",
          prop: "role_name",
          isShow: true,
        },
      ],

      // 当前编辑数据
      current: null,
      // 是否显示编辑弹窗
      showEdit: false,
      // 编辑回显数据
      editData: null,

      //字典类目
      showcategoryEdit: false,
      category: null,

      //字典详情数据
      dictionaryData: null,
      showDetails: false,

      pid: 0,
    };
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    this.getplate();
  },

  methods: {
    /* 刷新表格 */
    reload() {
      this.getplate();
    },
    /* 重置搜索 */
    reset() {
      this.where = {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      };
      this.reload();
    },

    // 获取板块
    getplate() {
      getDictionary()
        .then((res) => {
          this.DictList = res.data;
          this.DictList_copy = res.data;
          this.Dictloading = false;
          this.pid = this.DictList[0].id;
          // console.log(this.DictList)
          // console.log(this.DictList[0].id)
          // 获取字典类目列表
          getDictcategory(this.pid, this.where)
            .then((res) => {
              this.List = res.data.list;
              this.total = res.data.count;
              this.loading = false;
            })
            .catch((err) => {
              this.$message.error(err.msg);
              this.loading = false;
            });
        })
        .catch((err) => {
          this.$message.error(err.msg);
          this.Dictloading = false;
        });
    },

    //获取字典类目列表
    getList(id) {
      getDictcategory(id, this.where)
        .then((res) => {
          this.List = res.data.list;
          this.total = res.data.count;
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err.msg);
          this.loading = false;
        });
    },
    //
    // 查询条件
    QueryList() {    
      // this.DictList = 0;  
      // this.DictList =  this.DictList_copy.filter((item)=>{
      //   return item.role_name.includes(this.where.facilitator_name)
      // })
      this.getplate();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val);
      this.where.page = val;
      this.getList(this.pid);
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection);
      console.log("选择的所有数据行", selection);
      console.log("当前选择的数据行", row);
    },
    ChangeSize(val) {
      console.log("当前页面显示的条数", val);
      this.where.limit = val;
      this.getList(this.pid);
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name";
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA";
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name";
    },
     
    // 删除二级
    del(row) {
      //row为当前行数据
      //通过DelDictcategory这个删除接口并用row.id传参去删除
      DelDictcategory(row.id)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            //再次通过此方法获取一二级数据
            this.getplate();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //选择板块
    selectDict(selection, row) {
      this.where.page = 1;
      // console.log(1111);
      // console.log(selection)
      // console.log("选择的所有数据行", selection)
      // console.log("当前选择的数据行", row)
      // console.log("我被触发了")
      if (selection.length === 0) {
        this.current = null;
      } else {
        this.current = row;
      }

      this.getList(row.id);
      if (this.current != null) {
        this.pid = this.current.id;
      }
    },
    //实现单选
    handleSelectionChange(val) {
      // console.log(val)
      // console.log(this.$refs.Table)
      // console.log("selection rows:", val);
      this.$nextTick(() => {
        if (val.length > 1) {
          this.$refs.Table.clearSelection();
          this.$refs.Table.toggleRowSelection(val.pop());
        }
      });
    },

    // 编辑字典一级类目
    openEdit(row) {
      this.editData = row;
      this.showEdit = true;
    },
    // 删除一级里面
    remove(row) {
      DelDictcategory(row.id)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.getplate();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // 编辑字典
    openCategory(row) {
      if (row === null) {
        // 判断下是否选择了一级分类
        if (!this.current) {
          this.$message.error("请选择字典分类");
        } else {
          this.category = row;
          this.showcategoryEdit = true;
        }
      } else {
        this.category = row;
        this.showcategoryEdit = true;
      }
    },

    //点击字典数据
    openDetails(row) {
      this.dictionaryData = row;
      this.showDetails = true;
    },
    //接收字典数据子组件传递的数据
    showdetalis(value) {
      this.showDetails = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.no-multiple {
  // 隐藏多选
  ::v-deep .el-table__header-wrapper .el-checkbox {
    display: none;
  }
}
</style>
